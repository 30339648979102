import { memo } from 'react'
// @mui
import { Box } from '@mui/material'
//
import { StyledRootScrollbar, StyledScrollbar } from './styles'
import { ScrollbarProps } from './types'

// ----------------------------------------------------------------------

interface Props extends ScrollbarProps {
  center?: boolean
}

function Scrollbar({ children, sx, center, ...other }: Props) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    )
  }

  return (
    <StyledRootScrollbar sx={center ? { display: 'flex', alignItems: 'center' } : {}}>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  )
}

export default memo(Scrollbar)
