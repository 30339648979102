// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  
  company: {
    root: path(ROOTS_DASHBOARD, '/company'),
    create: path(ROOTS_DASHBOARD, '/company/create'),
    edit: {
      company: (id: number | string ) => path(ROOTS_DASHBOARD, `/company/${id}/edit/company`),
      organization: (id: number | string) => path(ROOTS_DASHBOARD, `/company/${id}/edit/organization`),
      subscription: {
        list: (companyId: number | string) => path(ROOTS_DASHBOARD, `/company/${companyId}/edit/subscription`),
        create: (companyId: number | string) => path(ROOTS_DASHBOARD, `/company/${companyId}/edit/subscription/create`),
        edit: (companyId: number | string, id: number | string) => path(ROOTS_DASHBOARD, `/company/${companyId}/edit/subscription/edit/${id}`),
      },
      contract: (companyId: number | string) => path(ROOTS_DASHBOARD, `/company/${companyId}/edit/contract`),
      invoice: (companyId: number | string) => path(ROOTS_DASHBOARD, `/company/${companyId}/edit/invoice`), 
    }
  },

  subscription: {
    root: path(ROOTS_DASHBOARD, '/subscription')
  },

  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice')
  },

  referral: {
    root: path(ROOTS_DASHBOARD, '/referral')
  }
};
