import { useState, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// components
import LoadingScreen from '../components/loading-screen'
//
import Login from '../pages/LoginPage'
import { useAuthContext } from './useAuthContext'

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext()

  const { pathname } = useLocation()

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

  let subdomain: string
  if (window.location.host.split('.')[0] === 'www') {
    subdomain = window.location.host.split('.')[1]
  } else {
    subdomain = window.location.host.split('.')[2]
  }

  if (subdomain === 'sales') {
    return <Navigate to="/referral" />
  }

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <> {children} </>
}
