import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

// ----------------------------------------------------------------------

type ReferralGuardProps = {
  children: ReactNode
}

export default function ReferralGuard({ children }: ReferralGuardProps) {
  let subdomain: string
  if (window.location.host.split('.')[0] === 'www') {
    subdomain = window.location.host.split('.')[1]
  } else {
    subdomain = window.location.host.split('.')[2]
  }

  if (subdomain === 'admin') {
    return <Navigate to="/dashboard" />
  }

  return <> {children} </>
}
