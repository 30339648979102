import { Suspense, lazy, ElementType } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')))

export const CompanyPage = Loadable(lazy(() => import('../pages/company/Company')))
export const CreateCompanyPage = Loadable(lazy(() => import('../pages/company/Create')))
export const EditCompanyPage = Loadable(lazy(() => import('../pages/company/Edit')))

export const EditCompanyForm = Loadable(lazy(() => import('src/sections/company/CompanyEditForm')))
export const EditOrganizationList = Loadable(
  lazy(() => import('src/sections/company/organization'))
)
export const CompanySubscriptionPage = Loadable(
  lazy(() => import('src/sections/company/subscription'))
)

export const CompanySubscriptionList = Loadable(
  lazy(() => import('src/sections/company/subscription/SubscriptionList'))
)
export const EditCompanySubscription = Loadable(
  lazy(() => import('src/sections/company/subscription/Edit'))
)
export const CreateCompanySubscription = Loadable(
  lazy(() => import('src/sections/company/subscription/Create'))
)

export const CompanyContractList = Loadable(
  lazy(() => import('../sections/company/contract/ContractList'))
)

export const CompanyInvoiceList = Loadable(
  lazy(() => import('../sections/company/invoice/InvoiceList'))
)

export const SubscriptionPage = Loadable(lazy(() => import('../pages/Subscription')))

export const InvoicePage = Loadable(lazy(() => import('../pages/Invoice')))

export const Page404 = Loadable(lazy(() => import('../pages/Page404')))

export const ReferralPage = Loadable(lazy(() => import('../pages/Referral')))
export const ReferralList = Loadable(lazy(() => import('../pages/ReferralList')))
