import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    // const theme = useTheme();

    // const PRIMARY_LIGHT = theme.palette.primary.light;

    // const PRIMARY_MAIN = theme.palette.primary.main;

    // const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 74 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M70.4292 1.96851H42.2067C40.5766 1.96851 39.2552 3.28997 39.2552 4.92008V33.1426C39.2552 34.7727 40.5766 36.0942 42.2067 36.0942H70.4292C72.0593 36.0942 73.3808 34.7727 73.3808 33.1426V4.92008C73.3808 3.28997 72.0593 1.96851 70.4292 1.96851Z"
            fill="#100447"
          />
          <path
            d="M70.4292 0H42.2067C40.5766 0 39.2552 1.32146 39.2552 2.95157V31.1741C39.2552 32.8042 40.5766 34.1256 42.2067 34.1256H70.4292C72.0593 34.1256 73.3808 32.8042 73.3808 31.1741V2.95157C73.3808 1.32146 72.0593 0 70.4292 0Z"
            fill="#1A0289"
          />
          <path
            d="M31.8668 1.96851H3.64432C2.01421 1.96851 0.692749 3.28997 0.692749 4.92008V33.1426C0.692749 34.7727 2.01421 36.0942 3.64432 36.0942H31.8668C33.4969 36.0942 34.8184 34.7727 34.8184 33.1426V4.92008C34.8184 3.28997 33.4969 1.96851 31.8668 1.96851Z"
            fill="#100447"
          />
          <path
            d="M31.8668 0H3.64432C2.01421 0 0.692749 1.32146 0.692749 2.95157V31.1741C0.692749 32.8042 2.01421 34.1256 3.64432 34.1256H31.8668C33.4969 34.1256 34.8184 32.8042 34.8184 31.1741V2.95157C34.8184 1.32146 33.4969 0 31.8668 0Z"
            fill="#1A0289"
          />
          <path
            d="M33.4863 34.0758H2.04156C0.914337 34.0758 0 33.1614 0 32.0342L0.778666 17.7505H34.905L35.5278 32.0342C35.5278 33.1614 34.6135 34.0758 33.4863 34.0758Z"
            fill="#1A0289"
          />
          <path
            d="M71.9592 34.0758H40.6703C39.5431 34.0758 38.6288 33.1614 38.6288 32.0342L39.2516 17.7505H73.3772L74 32.0342C74 33.1614 73.0857 34.0758 71.9584 34.0758H71.9592Z"
            fill="#1A0289"
          />
          <path d="M34.8516 5.9126H0.740387V17.7506H34.8516V5.9126Z" fill="#1A0289" />
          <path d="M13.1406 9.75464V24.4324H10.0476V9.75464H13.1406V9.75464Z" fill="white" />
          <path
            d="M14.8704 24.4324V9.75464H20.653C21.763 9.75464 22.7098 9.95165 23.4949 10.3457C24.2794 10.7397 24.8805 11.2961 25.2976 12.0156C25.7147 12.7344 25.9233 13.5801 25.9233 14.5522C25.9233 15.5243 25.7133 16.3614 25.2926 17.0643C24.8726 17.7672 24.2635 18.3041 23.4653 18.675C22.6672 19.046 21.7038 19.2314 20.5744 19.2314H16.7027V16.7395H20.0714C20.6689 16.7395 21.1633 16.6573 21.5537 16.4935C21.9441 16.3296 22.2364 16.0864 22.4305 15.7646C22.6239 15.4427 22.7213 15.0393 22.7213 14.5529C22.7213 14.0665 22.6246 13.6487 22.4305 13.3167C22.2364 12.9855 21.9426 12.7322 21.5486 12.5583C21.1546 12.3844 20.6588 12.297 20.0613 12.297H17.9634V24.4331H14.8704V24.4324ZM23.0172 24.4324L19.4414 17.7535H22.7906L26.4451 24.4324H23.0172V24.4324Z"
            fill="white"
          />
          <path
            d="M46.5338 24.5962V9.91919H49.6268V22.0358H55.9211V24.5969H46.5338V24.5962Z"
            fill="white"
          />
          <path
            d="M57.1681 24.5962V9.91919H67.0584V12.4803H60.2611V15.9284H66.546V18.4405H60.2611V22.0358H67.0779V24.5969H57.1681V24.5962Z"
            fill="white"
          />
          <path
            d="M0.692749 17.8176H34.8191"
            stroke="white"
            strokeOpacity="0.9"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            d="M39.3216 17.8176H73.4472"
            stroke="white"
            strokeOpacity="0.9"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path d="M35.1468 15.2256H34.4908V20.41H35.1468V15.2256Z" fill="#100447" />
          <path d="M1.02117 15.2256H0.365189V20.41H1.02117V15.2256Z" fill="#100447" />
          <path d="M73.7091 15.2256H73.0532V20.41H73.7091V15.2256Z" fill="#100447" />
          <path d="M39.5835 15.2256H38.9276V20.41H39.5835V15.2256Z" fill="#100447" />
          <path
            d="M37.4215 31.9153C37.2113 31.9153 37.0309 31.8401 36.8803 31.6895C36.7298 31.5389 36.6545 31.3585 36.6545 31.1483C36.6545 30.9381 36.7298 30.7577 36.8803 30.6071C37.0309 30.4565 37.2113 30.3813 37.4215 30.3813C37.6318 30.3813 37.8122 30.4565 37.9627 30.6071C38.1133 30.7577 38.1886 30.9381 38.1886 31.1483C38.1886 31.2875 38.1531 31.4153 38.082 31.5318C38.0139 31.6483 37.9215 31.7421 37.8051 31.8131C37.6914 31.8813 37.5636 31.9153 37.4215 31.9153ZM37.4215 27.0574C37.2113 27.0574 37.0309 26.9821 36.8803 26.8315C36.7298 26.681 36.6545 26.5006 36.6545 26.2903C36.6545 26.0801 36.7298 25.8997 36.8803 25.7492C37.0309 25.5986 37.2113 25.5233 37.4215 25.5233C37.6318 25.5233 37.8122 25.5986 37.9627 25.7492C38.1133 25.8997 38.1886 26.0801 38.1886 26.2903C38.1886 26.4296 38.1531 26.5574 38.082 26.6739C38.0139 26.7903 37.9215 26.8841 37.8051 26.9551C37.6914 27.0233 37.5636 27.0574 37.4215 27.0574Z"
            fill="#1A0289"
          />
        </svg>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
          <defs>
            <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
              <stop offset="0%" stopColor={PRIMARY_DARK} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>

            <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
              <stop offset="0%" stopColor={PRIMARY_LIGHT} />
              <stop offset="100%" stopColor={PRIMARY_MAIN} />
            </linearGradient>
          </defs>

          <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
            <path
              fill="url(#BG1)"
              d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
            />
            <path
              fill="url(#BG2)"
              d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
            />
            <path
              fill="url(#BG3)"
              d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
            />
          </g>
        </svg> */}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
