// routes
// eslint-disable-next-line import/no-extraneous-dependencies
import { Business, Yard, Description, Attribution } from '@mui/icons-material'
import { PATH_DASHBOARD } from '../../../routes/paths'
// components
// import SvgColor from '../../../components/svg-color'
// ----------------------------------------------------------------------

// const icon = (name: string) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
// )

const ICONS = {
  company: <Business />,
  subscription: <Yard />,
  invoice: <Description />,
  referral: <Attribution />,
}

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Ерөнхий',
    items: [
      { title: 'Байгууллага', path: PATH_DASHBOARD.company.root, icon: ICONS.company },
      { title: 'Багц', path: PATH_DASHBOARD.subscription.root, icon: ICONS.subscription },
      { title: 'Нэхэмжлэл', path: PATH_DASHBOARD.invoice.root, icon: ICONS.invoice },
      { title: 'Referrals', path: PATH_DASHBOARD.referral.root, icon: ICONS.referral },
    ],
  },
]

export default navConfig
