import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
// components
import LoadingScreen from '../components/loading-screen'
//
import { useAuthContext } from './useAuthContext'

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext()

  let subdomain: string
  if (window.location.host.split('.')[0] === 'www') {
    subdomain = window.location.host.split('.')[1]
  } else {
    subdomain = window.location.host.split('.')[2]
  }

  if (subdomain === 'sales') {
    return <Navigate to="/referral" />
  }

  if (subdomain === 'admin') {
    return <Navigate to="/dashboard" />
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />
  }

  if (!isInitialized) {
    return <LoadingScreen />
  }

  return <> {children} </>
}
