import { Navigate, useRoutes } from 'react-router-dom'
// auth
import ReferralGuard from 'src/auth/ReferralGuard'
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
// layouts
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard'
// config
import { PATH_AFTER_LOGIN } from '../config-global'
//
import {
  Page404,
  LoginPage,
  CompanyPage,
  EditCompanyPage,
  CreateCompanyPage,
  EditCompanyForm,
  EditOrganizationList,
  CompanySubscriptionList,
  EditCompanySubscription,
  CreateCompanySubscription,
  CompanySubscriptionPage,
  SubscriptionPage,
  CompanyContractList,
  CompanyInvoiceList,
  InvoicePage,
  ReferralPage,
  ReferralList,
} from './elements'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'referral',
          element: (
            <ReferralGuard>
              <ReferralPage />
            </ReferralGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'company',
          children: [
            { element: <CompanyPage />, index: true },
            {
              path: ':id/edit',
              element: <EditCompanyPage />,
              children: [
                { path: 'company', element: <EditCompanyForm /> },
                { path: 'organization', element: <EditOrganizationList /> },
                {
                  path: 'subscription',
                  element: <CompanySubscriptionPage />,
                  children: [
                    { element: <CompanySubscriptionList />, index: true },
                    { element: <EditCompanySubscription />, path: 'edit/:subId' },
                    { element: <CreateCompanySubscription />, path: 'create' },
                  ],
                },
                {
                  path: 'contract',
                  element: <CompanyContractList />,
                },
                {
                  path: 'invoice',
                  element: <CompanyInvoiceList />,
                },
              ],
            },
            { path: 'create', element: <CreateCompanyPage /> },
          ],
        },
        { path: 'subscription', element: <SubscriptionPage /> },
        { path: 'invoice', element: <InvoicePage /> },
        { path: 'referral', element: <ReferralList /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
