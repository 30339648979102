import axios from "axios"
import { HOST_API } from "src/config-global"
import service from "./interceptor"

const baseURL = HOST_API

const AuthService = {
  getUser() {
    return service({
      url:'admin/auth/user',
      method: 'get',
    })
  },

  login(data: object) {
    return axios.post(`${baseURL}/admin/auth/login`, data)
  }
}

export default AuthService